// import { Web3Modal } from '@web3modal/ethers'
import CONFIG_VARIABLES from '@/constants/CONFIG_VARIABLES'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/vue'

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '5e3d1339594c5f9f8577f5a11403e01a'

// const bsc = {
//   chainId: 56,
//   name: 'Binance Smart Chain',
//   currency: 'BNB',
//   explorerUrl: 'https://bscscan.com',
//   rpcUrl: 'https://bsc-dataseed.binance.org/'
// }

// const bscTestnet = {
//   chainId: 97,
//   name: 'Binance Smart Chain Testnet',
//   currency: 'BNB',
//   explorerUrl: 'https://testnet.bscscan.com',
//   rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
// }

const metadata = {
  name: 'Nodesys',
  description: 'Admin Presale',
  url: 'https://nodesys.io',
  icons: ['https://nodesys.io/static/img/favicon.png']
}

const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  persistentSession: false,
})


export const modal = createWeb3Modal({
  ethersConfig,
  chains: CONFIG_VARIABLES.CORRECT_NETWORK_CONFIGS,
  projectId,
  enableAnalytics: true,
  enableOnramp: true
})


// async function getWeb3Modal() {
//   const web3Modal = new Web3Modal({
//     cacheProvider: false,
//     providerOptions: {
//       walletconnect: {
//         package: WalletConnectProvider,
//         options: {
//           infuraId: "your-infura-id"
//         },
//       },
//     },
//   })
//   return web3Modal
// }