<template>
  <div>
    <div id="test"></div>
    <InvestmentsForm />
  </div>
</template>

<script>
import InvestmentsForm from "@/views/investments-form.vue";
// import { mapActions } from "vuex";

export default {
  name: "App",
  components: { InvestmentsForm },
  methods: {
    // ...mapActions(["init"]),
  },
  mounted() {
    // this.$nextTick(async () => {
    //   this.isLoading = true;
    //   if (
    //     typeof localStorage.getItem("cellLoggedIn") !== "object" &&
    //     localStorage.getItem("cellLoggedIn") == "true"
    //   ) {
    //     await this.init(true);
    //   }
    //   if (this.isConnected) {
    //     await this.fetchAllTokensAddresses();
    //     await this.fetchLPTokensBalances();
    //   }

    //   this.isLoading = false;
    // });
  },
};
</script>

<style lang="scss">
@import "../../assets/css/app.css";
</style>
