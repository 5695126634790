import BSC_ABI from "./BSC_ABI";
import BSC_TESTNET_ABI from "./BSC_TESTNET_ABI";
const origin = location.origin;
const isProduction = !origin.includes("test") && !origin.includes("127.0.0.1") && !origin.includes("localhost")

const bsc = {
    chainId: 56,
    name: 'Binance Smart Chain',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://bsc-dataseed.binance.org/'
}

const bscTestnet = {
    chainId: 97,
    name: 'Binance Smart Chain Testnet',
    currency: 'BNB',
    explorerUrl: 'https://testnet.bscscan.com',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'
}

const eth = {
    chainId: 1,
    name: 'Ethereum Mainnet',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://eth.llamarpc.com'
}

const goerli = {
    chainId: 5,
    name: 'Goerli',
    currency: 'ETH',
    explorerUrl: 'https://rpc.ankr.com/eth_goerli',
    rpcUrl: 'https://goerli.etherscan.io'
}

const linea = {
    chainId: 59144,
    name: 'Linea',
    currency: 'ETH',
    explorerUrl: 'https://lineascan.build',
    rpcUrl: 'https://rpc.linea.build'
}

const polygon = {
    chainId: 137,
    name: 'Polygon Mainnet',
    currency: 'MATIC',
    explorerUrl: 'https://polygonscan.com/',
    rpcUrl: 'https://polygon-rpc.com/'

}

const polygonTestnet = {
    chainId: 80001,
    name: 'Polygon Testnet',
    currency: 'MATIC',
    explorerUrl: 'https://mumbai.polygonscan.com',
    rpcUrl: 'https://rpc-mumbai.maticvigil.com'

}

const CONFIG_VARIABLES = Object.freeze({
    CORRECT_NETWORK_ID: isProduction ? 56 : 97,
    CORRECT_NETWORK_ID_HEX: isProduction ? "0x38" : "0x61",
    CORRECT_NETWORK_CONFIGS: [bsc, bscTestnet, eth, goerli, linea, polygon, polygonTestnet],
    CORRECT_NETWORK_EXPLORER_URL: isProduction ? "https://bscscan.com/" : "https://testnet.bscscan.com/",
    PRESALE_CONTRACT_ADDRESS: isProduction ? "0xcbb8c4a012fb0884826988d17352f30c9d92d251" : "0x727A9a7c685831807e24E60d0F5E5C0dFE55C619",
    PRESALE_CONTRACT_ABI: isProduction ? BSC_ABI : BSC_TESTNET_ABI,
    NODESYS_CONTRACT_ADDRESS: isProduction ? "0x279960F9CB21965b33Ad3F3Ced07349363cB2030" : "0xBE32Fb6431bC68A2B23dCc8c0bD5f93D9C3789b8",
    USDT_CONTRACT_ADDRESS: isProduction ? "0x55d398326f99059fF775485246999027B3197955" : "0xdb7558fb5a652d674920e65a7232fefaa7030349",
})

export default CONFIG_VARIABLES;