<template>
    <div class="warn-snackbar font-body-text font-mr-body-text font-text-mobile font-color-main-lighter ta-c ">
        <p class="warn-snackbar__text">
          {{ info.text }}
        </p>
        <button
          @click="callback"
          class="warn-snackbar__btn font-semibold font-body-text font-lr-body-text font-body-text-mobile"
          :class="{
            'warn-snackbar__btn_warn': info.type === warnTypes.warning,
            'warn-snackbar__btn_success': info.type === warnTypes.success,
            'warn-snackbar__btn_regular': info.type === warnTypes.regular
          }"
          type="button"
        >
          {{ btnName }}
        </button>
    </div>
  </template>
  
  <script>
  import warnTypes from "@/constants/WARN_TYPES";

  export default {
    name: "WarnSnackbar",
    props: {
      info: {
        type: Object,
        default: () => {
          return {
            type: warnTypes.regular,
            text: ""
          }
        }
      },
      btnName: {
        type: String,
        default: ""
      },
      callback: {
        type: Function,
        default: () => {}
      }
    },
    data() {
      return {
        warnTypes
      }
    }
  };
  </script>
  
<style lang="scss">
.warn-snackbar {
  position: fixed;
  max-width: calc(100% - 64px);
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 32px;
  background-color: #313C47;
  border-radius: 12px;
  z-index: 10000;

  &__btn {
    padding: 12px;
    border-radius: 16px;
    cursor: pointer;

    &:hover {
      background: #232B33;
    }

    &_warn {
      color: #f76487;
    }

    &_success {
      color: #6CEEB0;
    }

    &_regular {
      color: #647FF7;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}
</style>
  