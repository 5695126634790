import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import RU from "./locale/ru.json";
import EN from "./locale/en.json";
import store from "./store";
import App from "./App.vue";

const app = createApp(App);
const userLang = navigator.language || navigator.userLanguage;
const lang = userLang === "ru" ? "ru" : "en";
const cookieLocale = document.cookie.match(/django_language=[^\s;]+/);
try {
  console.log("userLang")
  console.log(userLang)
  console.log("lang")
  console.log(lang)
  console.log("cookieLocale")
  console.log(cookieLocale)
  console.log("cookieLocale[0].split('=')[1]")
  console.log(cookieLocale[0].split("=")[1])
} catch (e) {
  console.log("lang error")
  console.log(e)
}
const i18n = createI18n({
  locale: cookieLocale ? cookieLocale[0].split("=")[1] : lang,
  messages: {
    ru: RU,
    en: EN,
  },
});
app.use(store);
app.use(i18n);
app.mount("#app");
